import {
  SlRadio
} from "./chunk.QQTW5PZI.js";

// src/components/radio/radio.ts
var radio_default = SlRadio;
SlRadio.define("sl-radio");

export {
  radio_default
};
